<template>
  <div style="overflow: hidden">
    <span v-for="item in btnList" :key="item" class="btn">
      <el-button
        v-if="item.split(',').length == 1 || $utils.ruleShow2(item.split(',')[1])"
        :type="(item == '发布人才招聘' || item == '发布工程劳务需求') && btnType == 1 ? 'danger' : 'primary'"
        class="mr-5"
        @click="goCheck(item)"
        size="mini"
      >
        <i class="el-icon-plus" v-if="item.indexOf('添加') > -1"></i>
        <i class="el-icon-receiving" v-if="item.indexOf('导出数据') > -1"></i>
        <i class="el-icon-receiving" v-if="item.indexOf('全部导出') > -1"></i>
        <i class="el-icon-upload2" v-if="item.indexOf('导入') > -1"></i>
        <i class="el-icon-download" v-if="item.indexOf('下载') > -1"></i>
        <i class="el-icon-s-promotion" v-if="item.indexOf('推送') > -1"></i>
        <i class="el-icon-plus" v-if="item.indexOf('发布') > -1"></i>
        <i class="el-icon-s-promotion" v-if="item.indexOf('一键询价') > -1"></i>
        <i class="el-icon-delete" v-if="item.indexOf('删除') > -1"></i>
        {{ item.split(',')[0] }}
      </el-button>
      <input v-if="item.indexOf('导入') > -1 || item.indexOf('替换') > -1" ref="file" @change="importDoc" type="file" id="fileInput" />
    </span>
    <slot name="msg"></slot>

    <div style="float: right" class="col mb-10" v-if="columns.length > 0 && mei">
      <el-dropdown>
        <span class="el-dropdown-link">
          <div class="choose" style="text-align: center">
            <i class="el-icon-s-grid"></i>
            选择列
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-scrollbar style="height: 200px">
            <div style="min-width: 80px; padding-left: 14px; padding-right: 14px">
              <el-checkbox-group v-model="checkList" @change="getChecked">
                <el-checkbox
                  style="display: block"
                  class="mb-5"
                  :label="item.label"
                  :value="item.prop"
                  v-for="(item, index) in columns.filter((item) => item.type != 'selection' && item.label != '操作')"
                  :key="index"
                >
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-scrollbar>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      :data="list"
      lazy
      style="margin-top: 20px; width: 100%"
      :header-cell-style="{
        padding: '10px 0px',
        background: '#fafafa',
        color: ' #666',
        fontWeight: 'normal',
      }"
      :max-height="height"
      :height="head_height"
      :border="true"
      row-key="id"
      :load="loadTreeData"
      ref="table"
      :row-style="{ fontSize: '13px' }"
      @selection-change="handleSelectionChange"
      @row-click="rowclick"
      :tree-props="{ children: 'sons', hasChildren: 'hasChildren' }"
      :row-class-name="tableRowClassName"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!--数据源api/admin.project.item/material_detail_list-->
      <template v-for="(column, index) in columns" :index="index">
        <el-table-column type="selection" v-if="column.type === 'selection'" :key="column.prop"></el-table-column>
        <el-table-column
          v-if="checkList.includes(column.label) && column.type !== 'selection'"
          header-align="center"
          :type="column.type"
          :sortable="column.hasSort"
          :key="column.label"
          :prop="column.prop"
          :label="column.label"
          :fixed="column.fixed"
          :align="column.align ? column.align : 'left'"
          :show-overflow-tooltip="true"
          :width="column.width"
        >
          <template slot-scope="scope">
            <template v-if="column.render">
              <ex-slot :render="column.render" :row="scope.row" :index="scope.$index" :column="column" />
            </template>
            <template v-else>
              <template v-if="column.prop == 'needslot'">
                <slot name="extra" :row="scope.row" :scope="scope"> </slot>
              </template>
              <template v-if="column.edit == true && edit">
                <el-input clearable v-model="scope.row[column.prop]" placeholder=""></el-input>
              </template>
              <template v-if="column.type == 'img'">
                <el-image style="width: 80px; height: 80px" :src="`${$url}${scope.row[column.prop]}`" :preview-src-list="[`${$url}${scope.row[column.prop]}`]" fit="contain" />
              </template>
              <template v-if="column.type == 'image'">
                <el-image style="width: 80px; height: 80px" :src="scope.row[column.prop]" :preview-src-list="[scope.row[column.prop]]" fit="contain" />
              </template>
              <template v-if="!column.edit && edit">
                {{ scope.row[column.prop] }}
              </template>
              <template v-if="column.prop === 'index'">
                <span v-text="getIndex(scope.$index)"></span>
              </template>
              <template v-if="column.prop === 'index2'">
                <span v-text="getIndex2(scope.$index)"></span>
              </template>
              <template v-if="column.label == '操作'">
                <slot name="oper" :row="scope.row" :scope="scope"> </slot>
              </template>
              <template v-if="column.slot">
                <slot :name="column.slotName" :row="scope.row" :scope="scope"> </slot>
              </template>
              <template v-else-if="column.type != 'selection' && !edit && column.type != 'img' && column.type != 'image'">
                <span v-if="column.light" class="light" @click="jump(scope.row, column.prop, scope, column)">
                  {{ scope.row[column.prop] }}
                </span>
                <span v-else> {{ scope.row[column.prop] }}</span>
              </template>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      class="mt-20"
      v-if="!nopage"
      background
      style="text-align: right"
      :total="total"
      :page-size.sync="page_size1"
      :current-page.sync="page1"
      :page-sizes="[10, 20, 30]"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      v-bind="$attrs"
    >
    </el-pagination>
  </div>
</template>

<script>
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null,
    },
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index,
    };
    if (data.props.column) params.column = data.props.column;

    return data.props.render(h, params);
  },
};
// import Table from './table';
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    mei: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    ex: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    page_size: {
      type: Number,
      default: 10,
    },
    nopage: {
      type: Boolean,
      default: false,
    },
    btnList: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    operator: {
      type: Array,
      default: () => [],
    },
    importurl: {
      type: String,
      default: '',
    },
    requstinfo: {
      type: Object,
      default: () => {},
    },
    titleTxt: {
      type: String,
      default: '',
    },
    clickId: {
      type: String,
      default: '',
    },
    noIm: {
      type: Boolean,
      default: false,
    },
    isAlign: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Boolean,
      default: false,
    },
    head_height: {
      type: String,
      default: null,
    },
  },
  computed: {
    // currentPage: {
    //   get () {
    //     return this.page
    //   },
    //   set (val) {
    //     this.$emit('update:page', val)
    //   }
    // },
    // pageSize: {
    //   get () {
    //     return this.limit
    //   },
    //   set (val) {
    //     this.$emit('update:limit', val)
    //   }
    // }
  },
  components: {
    // Table
    exSlot,
  },
  watch: {
    page(val) {
      this.page1 = val;
    },
    page_size(val) {
      this.page_size1 = val;
    },
    nopage(val) {
      // console.log(val, '分页的数据变化');
      if (val) {
        this.page1 = 1;
        this.page_size1 = 10;
      }
    },
    ex(newV) {
      this.$refs.table.clearSelection();
    },
    list(newV) {
      // this.$nextTick(() => {
      // let height = document.getElementById('page')
      // console.log(height.clientHeight)
      // this.maxHeight =( height.clientHeight - 15 ) + 'px'
      // console.log(this.maxHeight)
      // })
    },
    columns() {
      let num = document.getElementById('app').clientHeight;

      this.checkList = this.columns.map((item) => item.label);
      if (this.isNow) {
        this.checkList = this.checkList.filter((currentValue, index, arr, thisValue) => {
          return currentValue != '操作';
        });
      }

      // setTimeout(() => {
      //   this.show = true;
      // }, 5000);
    },
  },
  computed: {
    height() {
      setTimeout(() => {
        let num = document.getElementById('search')?.clientHeight + document.getElementById('chead').clientHeight + document.getElementById('count')?.clientHeight + 190;

        return 'calc(100vh - ' + num + 'px)';
      }, 500);
    },
  },
  data() {
    return {
      checkList: [],
      page1: 1,
      page_size1: 10,
      show: false,
      maxHeight: 0,
      selection: [],
      btnType: 0,
      isNow: false,
      currentRowdata: null,
    };
  },
  created() {
    this.btnType = this.$route.query.queryType;
    let only = sessionStorage.getItem('operation');
    if (only == 'only') {
      this.isNow = true;
    } else {
      this.isNow = false;
    }
  },
  mounted() {
    this.page1 = this.page == 10 ? this.page1 : this.page;
    this.page_size1 = this.page_size == 10 ? this.page_size1 : this.page_size;
    let num = document.getElementById('app').clientHeight;
    this.checkList = this.columns.map((item) => item.label);
    if (this.isNow) {
      this.checkList = this.checkList.filter((currentValue, index, arr, thisValue) => {
        return currentValue != '操作';
      });
    }
    setTimeout(() => {
      this.show = true;
    }, 5000);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.currentRow && this.currentRowdata == row) {
        return 'selected-row';
      }
      if (row.clear_war == 1 && this.titleTxt == '清标') {
        return 'warning-row';
      } else if (row.set_war == 1 && this.titleTxt == '结算') {
        return 'warning-row';
      } else if (row.progress_war == 1 && this.titleTxt == '进度') {
        return 'warning-row';
      } else if (row.person_war == 1 && this.titleTxt == '人员') {
        if (this.clickId == row.id) {
          return 'warning-row2';
        }
        return 'warning-row';
      } else if (row.is_warn == 1 && this.titleTxt == '机械') {
        if (this.clickId == row.id) {
          return 'warning-row2';
        }
        return 'warning-row';
      } else if (row.is_warn == 1 && this.titleTxt == '材料') {
        if (this.clickId == row.id) {
          return 'warning-row2';
        }
        return 'warning-row';
      } else if (row.warn_status && row.warn_status == 1 && !this.titleTxt) {
        return 'warning-row';
      }
      return '';
    },
    loadTreeData() {},
    goCheck(item) {
      // if (this.isNow) {
      //   this.$message.error("当前身份只能查看");
      //   return
      // }
      if (item.indexOf('导出数据') > -1) {
        if (this.selection.length == 0) {
          this.$message.error('请先选择数据');
          return;
        }
      }
      let arr = [];
      let arrT = JSON.parse(JSON.stringify(this.columns));
      arrT.forEach((item) => {
        this.checkList.forEach((item2) => {
          if (item.type == 'index') {
            item.prop = 'id';
          }
          if (item.label == item2 && item.type != 'selection' && item.prop != 'operation') {
            let obj = {};
            const name = item.prop;
            obj[name] = item.label;
            arr.push(obj);
          }
        });
      });
      this.$emit('choose', arr);
      this.$emit('btn', item.split(',')[0]);
      // setTimeout(() => {
      //   this.$refs.table.clearSelection();
      // }, 4000);
    },
    importDoc(e) {
      let form = new FormData();
      form.append('file_name', e.target.files[0]);
      // form.append('project_id', sessionStorage.getItem('projectId'))
      this.$http({
        url: 'api/admin.upload/upload',
        data: form,
        done: (res) => {
          if (this.noIm) {
            this.importReal2(res);
          } else {
            this.importReal(res, () => {
              e.target.value = '';
            });
          }
        },
      });
    },
    importReal(url, fn) {
      this.$http({
        url: this.importurl,
        data: {
          file_src: url,
          ...this.requstinfo,
        },
        done: (res) => {
          this.$emit('finish', true);
          this.$refs.file.value = '';
          this.$message.success('导入成功');
          document.querySelector('#fileInput').setAttribute('type', 'text');
          document.querySelector('#fileInput').setAttribute('type', 'file');
          fn();
        },
        fail: () => {
          fn();
        },
      });
    },
    importReal2(url) {
      this.$http({
        url: this.importurl,
        data: {
          ...this.requstinfo,
          detail_src: url,
        },
        done: (res) => {
          this.$emit('finish', this.requstinfo);
          this.$refs.file.value = '';
          this.$message.success('导入成功');
          document.querySelector('#fileInput').setAttribute('type', 'text');
          document.querySelector('#fileInput').setAttribute('type', 'file');
        },
      });
    },
    getChecked(e) {
      console.log(this.checkList);
    },
    getClientHeight() {
      let clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        const clientHeight = document.body.clientHeight < document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight;
      } else {
        const clientHeight = document.body.clientHeight > document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight;
      }
      return clientHeight;
    },
    handleSelectionChange(e) {
      this.selection = e;
      this.$emit(
        'exdata',
        e.map((item) => item.id)
      );
      this.$emit('exdata2', e);
      this.$emit(
        'exdata3',
        e.map((item) => item.project_id)
      );
    },
    jump(row, prop, all, column) {
      // if (this.isNow && prop != 'company_name' && prop != 'material_name' && prop != 'machine_name') {
      //   this.$message.error("当前身份只能查看");
      //   return
      // }
      row.prop = prop;
      row.index = all.$index;
      this.$emit('clicked', row);
    },
    getIndex(index) {
      // console.log(index,'798789',this.page_size)
      return (this.page - 1) * this.page_size1 + index + 1;
    },
    getIndex2(index) {},
    handleClick(index, row, label) {
      this.$emit('handleoperationClick', { index, row, label });
    },
    handleSizeChange(limit) {
      // this.page_size = limit
      this.page_size1 = limit;
      this.$emit('pagination', { page: this.page1, page_size: limit });
      if (this.autoScroll) {
        // scrollTo(0, 800)
      }
    },
    // 监听当前是第几页（page）
    handleCurrentChange(page) {
      this.page1 = page;

      this.$emit('pagination', { page: page, page_size: this.page_size1 });
      // if (this.autoScroll) {
      //   scrollTo(0, 800)
      // }
    },
    //点击某一行触发时间
    rowclick(row, column, event) {
      // console.log(row, column, event, '点击了某一行');
      this.currentRowdata = row;
      this.$emit('rowclick', row);
    },
  },
};
</script>

<style lang="less">
.btn {
  position: relative;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 90px;
  }
}

.btn i {
  font-size: 15px;
}

/deep/ .el-table__empty-block {
  text-align: center;
}

.choose {
  width: 85px;
  height: 30px;
  background: #5da0f9;
  border-radius: 3px 3px 3px 3px;
  color: white;
  line-height: 30px;
  font-size: 13px;
  text-align: center !important;
  cursor: pointer;
}

.empty {
  // color: #96A0B5;
  // // background: url(../assets/img/no_data.png) no-repeat;
  // background-size: 100% 100%;
  // width: 300px;
  // height: 300px;
  // position: relative;
  // margin: 30px;
  .no-data-1 {
    position: absolute;
    bottom: 18px;
    left: 140px;
    font-size: 19px;
  }

  .no-data-2 {
    position: absolute;
    bottom: 3px;
    left: 87px;
    font-size: 12px;
  }
}

.el-table .warning-row {
  background: #ffeeee;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .warning-row2 {
  background: #e5c1c1;
}
.selected-row {
  background-color: rgba(33, 160, 255, 0.3) !important; /* 设置点击后的行颜色 */
}
// /deep/ .el-table__empty-text{
// 	width: auto;
// 	img {
// 		align-self: center;
// 		width: 294px;
// 		height: 254px;
// 		padding: 30px;
// 		margin-top: 20px;
// 	}
// }

/* /deep/ .el-table td.el-table__cell {
    text-align: left !important;
  }

  .el-table .cell.el-tooltip {
    text-align: left !important;
  } */
</style>